const HamburgerIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 10 10">
      <path d="M0,1 10,1" stroke="#fff" stroke-width="1" />
      <path d="M0,5 10,5" stroke="#fff" stroke-width="1" />
      <path d="M0,9 10,9" stroke="#fff" stroke-width="1" />
    </svg>
  );
};

export { HamburgerIcon as default };
