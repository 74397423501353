import { Fragment } from "preact";
import { useEffect, useState } from "preact/hooks";
import FetchRSSFeed from "./FetchRSSFeed";
import { Container } from "./styles/Container.styled";
import { StyledParagraph } from "./styles/Paragraph.styled";
import root from "react-shadow/styled-components";
import DOMPurify from "dompurify";
import ParseRSSFeed from "./ParseRssFeed";

function DisplayRSSFeed(props) {
  const [feedItems, setFeeditems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    setIsLoading(true);
    const feedContents = await FetchRSSFeed(props.feed);
    const items = ParseRSSFeed(feedContents);
    setFeeditems(items);
    setIsLoading(false);
  }, []);
  console.log("feedItems after setFeeditems called", feedItems);

  console.log("feedItems in Display RSSFeedDisplay component", feedItems);
  return (
    <Fragment>
      {!isLoading && (
        <root.div>
          {feedItems.map(
            (item, index) =>
              index < 5 && (
                <Container>
                  <h2>{item.title}</h2>
                  <StyledParagraph
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  ></StyledParagraph>
                </Container>
              )
          )}
        </root.div>
      )}
      {!isLoading && feedItems.length === 0 && <p>No valid feed...</p>}
      {isLoading && <p>Loading...</p>}
    </Fragment>
  );
}

export default DisplayRSSFeed;
