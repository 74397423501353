import { useState } from "preact/hooks";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import toastStyles from "./styles.scss";

const SupportToast = (props) => {
  // Displays closing button
  const [showA, setShowA] = useState(true);
  const toggleShowA = () => setShowA(!showA);

  let toastHeaderText = "Cascade Support Site";
  let toastBodyText = "Virtual Office Hours open 3-5 p.m. every weekday";
  let toastBGColor;

  let start = 15 * 60;
  let end = 17 * 60;
  let saturday = 6;
  let sunday = 0;
  let now = new Date();
  let time = now.getHours() * 60 + now.getMinutes();
  let day = now.getDay();

  function inTime() {
    return time >= start && time < end && day !== saturday && day !== sunday;
  }

  if (props.officeHours === "open") {
    toastBodyText = "Questions? Virtual Office Hours is now live!";
    toastBGColor = "toastOfficeHoursOpen";
  } else if (props.officeHours === "closed") {
    toastBodyText = "Virtual Office Hours 3-5 p.m. workdays";
    toastBGColor = "toastOfficeHoursClosed";
  } else if (inTime()) {
    toastBodyText = "Questions? Virtual Office Hours is now live!";
    toastBGColor = "toastOfficeHoursOpen";
  } else {
    toastBodyText = "Virtual Office Hours 3-5 p.m. workdays";
    toastBGColor = "toastOfficeHoursClosed";
  }

  return (
    <div>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
        crossorigin="anonymous"
      />

      <style dangerouslySetInnerHTML={{ __html: toastStyles }} />

      <ToastContainer className="ToastContainer bottom-0 start-0 p-1">
        <Toast className={toastBGColor} show={showA} onClose={toggleShowA}>
          <Toast.Header className="toastHeader" closeButton={false}>
            <strong className="mr-auto">
              <a href="https://www.umt.edu/cascade-support/">
                {toastHeaderText}
              </a>
            </strong>
          </Toast.Header>
          <Toast.Body className="toastBody">
            <a href="https://umontana.zoom.us/j/93489304209">{toastBodyText}</a>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default SupportToast;
