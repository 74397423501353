import { Fragment } from "preact";
import { useRef, useEffect } from "preact/hooks";

const popStyle = {
  marginTop: "15px",
};

const bindAnchorLinks = (ref) => {
  var els = document.querySelectorAll("a[href='#rfi-form-h2']");
  for (let i = 0; i < els.length; i++) {
    els[i].addEventListener("click", () => {
      ref.current.scrollIntoView();
    });
  }
};

export default (props) => {
  const h2ref = useRef(null);
  const observer = new MutationObserver((mutations) => {
    bindAnchorLinks(h2ref);
  });
  useEffect(() => {
    let linksList = document.getElementById("sidebarNavList");
    if (linksList !== null) {
      observer.observe(linksList, { childList: true });
    }
    bindAnchorLinks(h2ref);
  }, []);

  return (
    <Fragment>
      <h2 id="rfi-form-h2" ref={h2ref} style={popStyle}>
        Request Information
      </h2>
      <p>{props.introContent}</p>
    </Fragment>
  );
};
