import styled from "styled-components";

const MOBILE_BREAKPOINT = "820px";

const LargeDisplay = styled.div`
  display: inherit;
  align-items: center;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: none;
  }
`;
const SmallDisplay = styled.div`
  display: none;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 820px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: stretch;
  }
`;

export { SmallDisplay, LargeDisplay, Row };
