import { Fragment } from "preact";
import style from "./alternative.scss";
import FlamaCondensedFontLoader from "../../utilities/FlamaCondensedFontLoader";
import GlobalCssLink from "../../utilities/GlobalCssLink";
import { useEffect } from "preact/hooks";

const UmtButton = (props) => {
  const buttonClass = props.type
    ? "btn btn-" + props.type
    : "btn btn-umt-maroon";
  const button = props.href ? (
    <a href={props.href} className={buttonClass}>
      {props.children}
    </a>
  ) : (
    <button className={buttonClass}>{props.children}</button>
  );
  useEffect(FlamaCondensedFontLoader, []);
  return (
    <Fragment>
      <GlobalCssLink />
      <style dangerouslySetInnerHTML={{ __html: style }} />
      {button}
    </Fragment>
  );
};

export default UmtButton;
