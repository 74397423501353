const FetchRSSFeed = async (feedUrl) => {
  // fetch rss feed using proxy url to fix cors error
  try {
    const response = await fetch(
      `https://api.allorigins.win/get?url=${feedUrl}`
    );
    const { contents } = await response.json();
    return contents;
  } catch (error) {
    console.error("Error: could not fetch ${feedUrl}", error);
    return "";
  }
};

export default FetchRSSFeed;
