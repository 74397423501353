import register from "preact-custom-element";
import SimpleRfiForm from "./SimpleRfiComponent";

if (!customElements.get("umt-rfi-form")) {
  register(
    SimpleRfiForm,
    "umt-rfi-form",
    ["data-major-codes", "introContent", "confirmationContent"],
    { shadow: true }
  );
}

export default SimpleRfiForm;
