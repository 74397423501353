import { useState, useRef, useEffect } from "preact/hooks";

const rfiSubmittedEvent = new Event("umt-rfi-submitted", { bubbles: true });

const invalidFeedbackStyle = {
  padding: "5px",
  fontSize: "1rem",
};

const buttonStyle = { marginBottom: "3px" };

const labelStyle = { fontSize: "1rem" };

export default (props) => {
  const [email, setEmail] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(props.loading ?? false);
  const input = useRef(null);
  const form = useRef(null);

  async function postRFI() {
    setInvalid(false);
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ email: email, majorCodes: props.dataMajorCodes }),
    };
    const response = await fetch(
      "https://rfi-form-api.appstest.umt.edu/api/simple_form_submission",
      requestOptions
    );
    const data = await response.json();
    if (!data.ok) {
      setInvalid(true);
      setValidationErrors(data.errors);
      input.current.focus();
    } else {
      document.dispatchEvent(rfiSubmittedEvent);
      props.setFinished(true);
    }
    setLoading(false);
  }

  if (props.validationErrors !== undefined) {
    setValidationErrors(props.validationErrors);
  }

  if (props.invalid !== undefined) {
    setInvalid(props.invalid);
  }

  if (props.loading !== undefined) {
    setLoading(props.loading);
  }

  if (props.email !== undefined) {
    setEmail(props.email);
  }

  return (
    <form
      noValidate
      className="form"
      onSubmit={(e) => {
        e.preventDefault();
        if (!loading) {
          postRFI();
        }
      }}
      ref={form}
    >
      <input type="hidden" name="majorCodes" value={props.dataMajorCodes} />
      <div class="form-group">
        <label for="email" style={labelStyle}>
          Email Address
        </label>
        <input
          ref={input}
          className={"form-control " + (invalid ? "is-invalid" : "")}
          type="email"
          name="email"
          id="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          aria-required="true"
          aria-describedby="emailValidationFeedback"
          aria-invalid={invalid ? "true" : "false"}
          disabled={loading ? true : false}
        />
        <div
          id="emailValidationFeedback"
          class="invalid-feedback"
          style={invalidFeedbackStyle}
        >
          {validationErrors.email}
        </div>
      </div>
      <button
        type="submit"
        className={"btn btn-primary btn-block"}
        style={buttonStyle}
      >
        {loading ? "Submitting..." : "Submit"}
      </button>
    </form>
  );
};
