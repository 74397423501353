import DisplayRSSFeed from "./DisplayRSSFeed";
import { Fragment } from "preact";
import "./UmtRSSFeed.scss";

export function UmtRSSFeed(props) {
  return (
    <Fragment>
      <DisplayRSSFeed feed={props.feed}></DisplayRSSFeed>
    </Fragment>
  );
}

export default UmtRSSFeed;
