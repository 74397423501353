import { Fragment } from "preact";
import root from "react-shadow/styled-components";
import UMLogoLink from "../../assets/UMLogoLink";
import { VisitLink, ApplyLink, GiveLink, GlobalNav } from "./Links";
import Search from "./Search";
import ModalMenu from "./ModalMenu";
import { SmallDisplay, LargeDisplay, Row } from "./Layout";
import styled from "styled-components";
//TODO update readme with import for StyledComponentsShaddowRoot
//TODO document/test case for global nav web component with content
//TODO would this be easier to reason about if we just switched out two components... one desktop and one mobile?
const UmtGlobalNav = (props) => {
  console.log(props);
  if (props.hideLinks == "true") {
    return <NavHiddenLinks {...props} />;
  } else if (props.logoOnly) {
    return <NavLogoOnly />;
  }
  return <NavFull {...props} />;
};

const NavHiddenLinks = (props) => {
  return (
    <Fragment>
      <style>{HostStyle}</style>
      <root.header aria-label="University of Montana">
        <style>{NavStyle}</style>
        <Row>
          <UMLogoLink />

          <Search query={props.searchQuery} />
        </Row>
        <GlobalNav />
      </root.header>
    </Fragment>
  );
};

const NavFull = (props) => {
  return (
    <Fragment>
      <style>{HostStyle}</style>
      <root.header aria-label="University of Montana">
        <style>{NavStyle}</style>
        <Row>
          <Utilities>
            <SmallDisplay>
              <UMLogoLink />
              <ModalMenu />
            </SmallDisplay>
            <LargeDisplay>
              <UMLogoLink />
              <GiveLink />
              <VisitLink />
              <ApplyLink />
            </LargeDisplay>
          </Utilities>
          <Search query={props.searchQuery} />
        </Row>
        <GlobalNav />
      </root.header>
    </Fragment>
  );
};

const NavLogoOnly = () => {
  return (
    <Fragment>
      <style>{HostStyle}</style>
      <root.header aria-label="University of Montana">
        <style>{NavStyle}</style>
        <Row>
          <UMLogoLink />
        </Row>
      </root.header>
    </Fragment>
  );
};

//TODO do we need this?
const Utilities = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 0px;
  margin: 0 10px;
  @media (max-width: 820px) {
    flex: 0 0 100%;
  }
  justify-content: space-between;
`;

// Host element needs <style> inlined unless
// we want to use just another <div>
const HostStyle = `
:host {
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #70002e;
  display: block;
  width: 100%;
}
header {
  margin: 0 auto;
  padding: 10px 0px;
  max-width: 1110px;
  background-color: #70002e;
}
`;

const NavStyle = `
nav ul {
  display: flex;
  list-style-type: none;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
}
nav ul a {
  color: white;
  text-decoration:none;
  margin: .5rem .25rem;
  padding: .5rem .25rem;
}
nav ul a:hover {
  text-decoration: underline;
}
@media (max-width: 820px) {
  nav ul {
    display: none;
  }
}
`;

export { UmtGlobalNav as default };
