import styled from "styled-components";
import VisuallyHidden from "@reach/visually-hidden";

const Search = (props) => {
  return (
    <SearchForm action="https://www.umt.edu/search" role="search" method="GET">
      <VisuallyHidden>
        <label htmlFor="um_search_box" className="sr-only">
          Search Text
        </label>
      </VisuallyHidden>

      <SearchInput
        id="um_search_box"
        name="q"
        type="text"
        value={props.query}
        placeholder="Search UM..."
      />

      <input
        name="cx"
        type="hidden"
        value="008446828893835516748:5zxralfycu4"
      />

      <input name="cof" type="hidden" value="FORID:10" />

      <SearchButton>
        <SearchIcon />
      </SearchButton>
    </SearchForm>
  );
};

const SearchForm = styled.form`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 0px;
  margin: 0 10px;
  @media (max-width: 820px) {
    flex: 0 0 100%;
  }
  justify-content: space-between;
`;

const SearchInput = styled.input`
  display: flex;
  border: 1px solid #62022a;
  background-color: #fff;
  border-bottom-color: #46001d;
  border-radius: 7px;
  color: rgb(41, 41, 41);
  height: 30px;
  padding: 5px 5px;
  margin: 0 5px 0 0;
  text-align: center;
  min-width: 200px;
  max-width: 500px;
  flex: 1;

  &:focus {
    outline: 1px solid white;
  }
  &:focus::placeholder {
    color: transparent;
  }
`;

const SearchButton = styled.button.attrs((props) => ({
  "aria-label": "Search",
  type: "submit",
}))`
  display: flex;
  vertical-align: middle;
  text-align: right;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 7px;
  color: #fff;
  padding: 7px;
  font-size: 1.2rem;

  &:hover {
    background-color: #5b182e;
    outline: 1px solid white;
    cursor: pointer;
  }
`;

const SearchIcon = styled.span.attrs((props) => ({
  "aria-hidden": "true",
}))`
  background-color: transparent;
  background-image: url(https://www.umt.edu/_files/images/search-icon.svg);
  background-repeat: no-repeat;
  background-position: 50% center;
  background-size: contain;
  width: 15px;
  height: 15px;
  display: inline-block;
  margin: 5px;
`;
export { Search as default };
