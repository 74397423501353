import register from "preact-custom-element";
import UmtSupportToast from "./UmtSupportToast";

if (!customElements.get("umt-support-toast")) {
  register(UmtSupportToast, "umt-support-toast", ["office-hours"], {
    shadow: true,
  });
}

export default UmtSupportToast;
