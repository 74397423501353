export default () => {
  let rootUrl = "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0";
  let newStyle = document.createElement("style");
  newStyle.appendChild(
    document.createTextNode(`
      @font-face {
          font-family: 'FontAwesome';
          src: url('${rootUrl}/fonts/fontawesome-webfont.eot?v=4.7.0');
          src: url('${rootUrl}/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),url('${rootUrl}/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),url('${rootUrl}/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),url('${rootUrl}/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),url('${rootUrl}/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
          font-weight: normal;
          font-style: normal
      }
    `)
  );

  document.head.appendChild(newStyle);
};
