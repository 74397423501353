import { Fragment } from "preact";
import { useEffect } from "preact/hooks";

import UMLogoLink from "../../assets/UMLogoLink";
import FontAwesome4FontLoader from "../../utilities/FontAwesome4FontLoader";
import FlamaCondensedFontLoader from "../../utilities/FlamaCondensedFontLoader";
import GlobalCssLink from "../../utilities/GlobalCssLink";

export function UmtGlobalFooter(props) {
  let d = new Date();
  let year = d.getFullYear();

  useEffect(FontAwesome4FontLoader, []);
  useEffect(FlamaCondensedFontLoader, []);

  return (
    <Fragment>
      <GlobalCssLink />
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
      />

      <body>
        <footer class="site-footer" id="siteFooter" aria-label="Footer">
          <div class="container">
            <div class="row flex-column flex-md-row m-mlr-0">
              <div class="col align-self-center">
                <UMLogoLink className="footer-logo a-um-logo" />
              </div>
              <div class="col">
                <ul>
                  <li>
                    <a href="tel:4062430000">
                      University Operator
                      <br /> 406-243-0211
                    </a>
                  </li>
                  <li>
                    <a href="https://www.umt.edu/contact/default.php">
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <address>
                      32 Campus Drive
                      <br /> Missoula, MT 59812
                    </address>
                  </li>
                </ul>
              </div>
              <div class="col">
                <ul>
                  <li>
                    <a href="https://www.umt.edu/accessibility">
                      Accessibility
                    </a>
                  </li>
                  <li>
                    <a href="https://www.umt.edu/a-to-z">A to Z</a>
                  </li>
                  <li>
                    <a href="https://www.umt.edu/directory/">Directory</a>
                  </li>
                  <li>
                    <a href="http://map.umt.edu">Campus Map</a>
                  </li>
                  <li>
                    <a href="https://www.umt.edu/safety/">Campus Safety</a>
                  </li>
                  <li>
                    <a href="https://umjobs.silkroad.com/">Employment</a>
                  </li>
                  <li>
                    <a href="https://apply.umt.edu/register/?id=324f3d51-df7a-490b-8806-ba22ea3e4227">
                      Refer A Griz
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col d-flex flex-column">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="https://www.facebook.com/umontana/"
                      aria-label="Facebook"
                    >
                      <i class="fa fa-facebook-square" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/umontana/"
                      target="_blank"
                      rel="noopener"
                      aria-label="Twitter"
                    >
                      <i class="fa fa-twitter-square" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/user/UniversityOfMontana"
                      target="_blank"
                      rel="noopener"
                      aria-label="Youtube"
                    >
                      <i class="fa fa-youtube-play" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/umontana"
                      target="_blank"
                      rel="noopener"
                      aria-label="Instagram"
                    >
                      <i class="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/school/the-university-of-montana/"
                      target="_blank"
                      rel="noopener"
                      aria-label="LinkedIn"
                    >
                      <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <p class="copyright">
              <small>
                &#169; {year} University of Montana.{" "}
                <a href="https://www.umt.edu/about/privacy">Privacy Policy</a>
              </small>
            </p>
          </div>
        </footer>
      </body>
    </Fragment>
  );
}

export default UmtGlobalFooter;
