import register from "preact-custom-element";
import UmtRSSFeed from "./UmtRSSFeed";

if (!customElements.get("umt-rss-feed")) {
  register(UmtRSSFeed, "umt-rss-feed", ["feed"], {
    shadow: true,
  });
}

export default UmtRSSFeed;
