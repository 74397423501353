const ParseRSSFeed = (xml) => {
  try {
    const feed = new window.DOMParser().parseFromString(xml, "text/xml");
    const items = feed.querySelectorAll("rss item, feed entry");
    const feedItems = [...items].map((el) => ({
      title: el.querySelector("title").innerHTML,
      description: el.querySelector("description, content").innerHTML,
    }));
    return feedItems;
  } catch (error) {
    console.error("Error parsing feed: ", error);
  }
  return [];
};

export default ParseRSSFeed;
