export default () => {
  let rootUrl = "https://www.umt.edu/_files/fonts";
  let newStyle = document.createElement("style");
  newStyle.appendChild(
    document.createTextNode(`
      @font-face {
        font-family: 'Flama Condensed';
        src: url('${rootUrl}/fonts/FlamaCondensed-Medium.woff2') format('woff2'),
            url('${rootUrl}/fonts/FlamaCondensed-Medium.woff') format('woff'),
            url('${rootUrl}/fonts/FlamaCondensed-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
      }
    `)
  );
  document.head.appendChild(newStyle);
};
