import { Fragment } from "preact";
import { useState, useRef } from "preact/hooks";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css"; //TODO browser issues warning about this style not being included
import styled from "styled-components";
import { GiveLink, ApplyLink, VisitLink, GlobalNav } from "./Links";
import HamburgerIcon from "./HamburgerIcon";

//TODO fix landscape phone mode
const ModalMenu = () => {
  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  const closeRef = useRef();
  return (
    <Fragment>
      <OpenDialogButton onClick={open} aria-label="Open Site Navigation">
        <HamburgerIcon size={20} />
      </OpenDialogButton>

      <Dialog isOpen={showDialog} onDismiss={close} initialFocusRef={closeRef}>
        <style>{inlineStyle}</style>

        <div className="buttons">
          <VisitLink />
          <ApplyLink />
          <GiveLink />
        </div>

        <GlobalNav hideLinks={false} />

        <button onClick={close} ref={closeRef}>
          Close
        </button>
      </Dialog>
    </Fragment>
  );
};

const OpenDialogButton = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
`;

/*
IMPORTANT: Inline styles are used as an escape hatch from Styled Components in the dialog.

Reach UI puts the dialog in a portal, which kills styled components (styled) because portals are rendered in the DOM outside of the component's DOM wrappers. When we wrap our content in web components, the Styled StyleSheetManager renders styled components CSS for a shadow root which the portal is outside.

The Reach UI component was selected because of the substantail accessibility features for the dialog.
*/

const inlineStyle = `
[data-reach-dialog-content] a {
  color: white;
  font-size: 1rem;
  line-height: 2rem;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
}
[data-reach-dialog-content] .buttons a,
[data-reach-dialog-content] .buttons a:visited {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 30px;
    margin: 0 0 .75rem 0;
    padding: 0 1rem;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 900;
    color: #5b182e;
    border: 2px solid #62022a;
    background-color: #fff;
    border-bottom-color: #46001d;
    border-radius: 7px;
  }
[data-reach-dialog-content] .buttons a:hover,
[data-reach-dialog-content] .buttons a:focus {
  background-color: #5b182e;
  color: #fff;
} 
[data-reach-dialog-content] nav ul{
  list-style-type: none;
  margin: .5rem 0;
  padding: 0;
}
[data-reach-dialog-content] nav a {
}
[data-reach-dialog-content] nav a:hover {
  text-decoration: underline;
}
[data-reach-dialog-content] button {
  width: 100%;
  margin: 1rem 0 0 0;
  padding: 0.25rem;
  min-height: 30px;
  background: none;
  border: 0;
  color: #fff;
  font-size: 0.75rem;
  cursor: pointer;
}
[data-reach-dialog-content] button:focus {
  outline: 3px solid white;
  color: white;
} 
[data-reach-dialog-overlay] {
  background: hsla(0, 0%, 0%, 0.33);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 100;
}

[data-reach-dialog-content] {
  width: 250px;
  margin: 10vh auto;
  background: white;
  padding: 2rem;
  outline: none;
  border-radius: 15px;
  background-color: #70002e;
}
`;
export { ModalMenu as default };
