import { useState, useEffect } from "preact/hooks";
import { useSpring, animated, config } from "react-spring";
import RfiForm from "./RfiForm";
import ThankYou from "./ThankYou";
import Intro from "./Intro";
import { useMeasure } from "react-use";
import FlamaCondensedFontLoader from "../../utilities/FlamaCondensedFontLoader";
import GlobalCssLink from "../../utilities/GlobalCssLink";

export default (props) => {
  const [finished, setFinished] = useState(false);
  const [contentRef, contentMeasurements] = useMeasure();
  const [thankYouRef, thankYouMeasurements] = useMeasure();

  const getAnimation = (show, translate) => {
    return {
      display: show ? "block" : "none",
      opacity: show ? 1 : 0,
      transform: show ? `translateX(0)` : `translateX(${translate})`,
    };
  };
  const finishedHeight = thankYouMeasurements.height + 30;
  const contentHeight = contentMeasurements.height + 30;

  const containerStyle = {
    borderStyle: "solid",
    borderColor: "#e1e1e1",
    marginTop: "17px",
    padding: "0px 5px 5px 5px",
    borderWidth: "medium",
    background: "#e1e1e1",
  };

  const animatedContainerStyle = useSpring({
    borderStyle: containerStyle.borderStyle,
    borderColor: finished ? "transparent" : containerStyle.borderStyle,
    marginTop: finished ? "" : containerStyle.marginTop,
    padding: finished ? "" : containerStyle.padding,
    borderWidth: containerStyle.borderWidth,
    background: finished ? "#fff" : containerStyle.background,
    height: finished ? `${finishedHeight}px` : `${contentHeight}px`,
  });

  if (props.finished !== undefined) {
    setFinished(props.finished);
  }

  useEffect(FlamaCondensedFontLoader, []);

  return (
    <body>
      <GlobalCssLink />
      <animated.div
        className="content-detail-sec"
        style={finished ? animatedContainerStyle : containerStyle}
      >
        <animated.div
          style={useSpring(getAnimation(!finished, "-200%"))}
          ref={contentRef}
        >
          <Intro introContent={props.introContent} />
          <RfiForm
            dataMajorCodes={props.dataMajorCodes}
            setFinished={setFinished}
            loading={props.loading}
          />
        </animated.div>
        <animated.div
          style={useSpring(getAnimation(finished, "100%"))}
          ref={thankYouRef}
        >
          <ThankYou confirmationContent={props.confirmationContent} />
        </animated.div>
      </animated.div>
    </body>
  );
};
