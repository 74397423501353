import styled from "styled-components";

const MenuButton = styled.a`
  &,
  &:visited {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 30px;
    font-size: 0.7rem;
    margin: 0 10px;
    padding: 0 1rem;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 900;
    color: #5b182e;
    border: 2px solid #62022a;
    background-color: #fff;
    border-bottom-color: #46001d;
    border-radius: 7px;
  }
  &:hover,
  &:focus {
    background-color: #5b182e;
    color: #fff;
  }
`;

const ApplyLink = () => (
  <MenuButton href="https://www.umt.edu/apply">Apply</MenuButton>
);

const VisitLink = () => (
  <MenuButton href="https://www.umt.edu/visit">Visit</MenuButton>
);

const GiveLink = () => (
  <MenuButton href="https://www.supportum.org/give">Support UM</MenuButton>
);

const TopNav = (props) => {
  return (
    <nav aria-label="University of Montana landing pages">
      <ul>
        <li>
          <a href="https://www.umt.edu/about/">About</a>
        </li>
        <li>
          <a href="https://www.umt.edu/academics/">Academics</a>
        </li>
        <li>
          <a href="https://www.umt.edu/admissions-and-financial-aid/">
            Admission and Aid
          </a>
        </li>
        <li>
          <a href="https://www.umt.edu/student-life/">Student Life</a>
        </li>
        <li>
          <a href="https://www.umt.edu/um-research/">Research</a>
        </li>
        <li>
          <a href="https://www.umt.edu/athletics/">Athletics</a>
        </li>
        <li>
          <a
            href="https://www.umt.edu/my/"
            title="Current Student and Employee Quick Links"
          >
            My UM
          </a>
        </li>
        <li>
          <a href="https://grizalum.org">Alumni</a>
        </li>
      </ul>
    </nav>
  );
};

const GlobalNav = styled(TopNav)`
  flex-basis: 100%;
  flex-grow: 1;
  display: ${(props) => (props.hideLinks ? "none" : "inherit")};
`;
export { ApplyLink, VisitLink, GiveLink, GlobalNav };
